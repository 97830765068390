import React, { useState, useCallback } from 'react'
import TextInput from '../text-input'
import Form from '../form'
import { useForm } from 'react-hook-form'
import PdfButton from '../pdf-button'
import BrochurePdf from 'static/brochure_061024.pdf'

import {
  PopupWrapper,
  CloseButtonWrap,
  CloseButton,
  Title,
  Button,
  ErrorMessage,
  ThanksMessage,
  Overlay,
} from './index.styled'

const PdfPopup = ({ open, onClose }) => {
  const form = useForm()
  const [isSubmit, setIsSubmit] = useState(false)

  const {
    formState: { errors },
  } = form

  const onSubmit = useCallback((input) => {
    console.log(input)
    fetch("/submit-form", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(input)
    })
      .then(() => {
        setIsSubmit(true);
        form.reset();
        window.open(BrochurePdf, '_blank');
      })
      .catch(() => setIsSubmit(true))
  }, [])

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      form.reset();
      setIsSubmit(false);
    }, 500);
  };

  return (
    <>
      <PopupWrapper className={open ? 'open' : ''}>
        <CloseButtonWrap onClick={handleClose}>
          <CloseButton />
        </CloseButtonWrap>
        <Title>Get the marketing brochure</Title>
        {!isSubmit ? (
          <Form onSubmit={onSubmit} formProps={form}>
            <TextInput
              name={'firstname'}
              title={'First Name'}
              required
              rules={{ required: true }}
            />
            {errors.firstname && (
              <ErrorMessage>*field required</ErrorMessage>
            )}
            <TextInput
              name={'lastname'}
              title={'Last Name'}
              required
              rules={{ required: true }}
            />
            {errors.lastname && (
              <ErrorMessage>*field required</ErrorMessage>
            )}
            <TextInput
              name={'email'}
              title={'Email'}
              required
              type="email"
              rules={{
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
              }}
            />
            {errors.email && (
              <ErrorMessage>*field required</ErrorMessage>
            )}
            <Button>
              <PdfButton className={'popup-download'} title='DOWNLOAD BROCHURE' />
            </Button>
          </Form>
        ) : (
          <ThanksMessage>
            {`Thank you for your interest. We’ll be in touch shortly.`}
          </ThanksMessage>
        )}
      </PopupWrapper>
      <Overlay className={open ? 'open' : ''} onClick={handleClose} />
    </>
  )
}

export default PdfPopup

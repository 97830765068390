import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
//import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'

export const Nubmer = styled.span`
  font-family: 'Mark Pro';
  font-style: normal;
  font-weight: 350;
  font-size: ${calculateResponsiveSize(16)};
  line-height: ${calculateResponsiveSize(14)};
  color: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
`
export const Title = styled((props) => <Link {...props} />)`
  font-family: 'Two45 Park Display';
  font-style: normal;
  font-weight: 400;
  font-size: ${calculateResponsiveSize(36)};
  line-height: ${calculateResponsiveSize(36)};
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  padding: ${calculateResponsiveSize(18)} 0;
  width: 100%;
  letter-spacing: ${calculateResponsiveSize(2)};
  text-transform: uppercase;
  white-space: break-spaces;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(24)};
  text-decoration: none;
  transition: 0.5s;
  &:hover {
    color: white;
    & ${Nubmer} {
      color: var(--brown);
    }
  }
`
export const Container = styled.div`
  display: flex;
  width: ${calculateResponsiveSize(700)};
  justify-content: center;
  gap: ${calculateResponsiveSize(12)};
  height: 100%;
  align-items: center;
  z-index: 2;
  position: relative;
  margin: 0 auto;
  flex-direction: column;
  &.mobile {
    width: 100%;
    gap: 0;
    justify-content: flex-start;
    ${Title} {
      padding: 24px;
      border-bottom: 2px solid var(--brown);
      font-size: 30px;
      line-height: 30px;
    }
    ${Nubmer} {
      font-size: 16px;
      line-height: 14px;
      color: var(--brown);
    }
  }
`

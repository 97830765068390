import React from 'react'
import { Title, InputField } from './index.styled'

const Input = ({
  className = '',
  title,
  type,
  error = false || null,
  value,
  textareaHeight,
  required = false,
  ...props
}) => {
  return (
    <div className={className}>
      {title && (
        <Title>
          {title}
          {/* {required ? '*' : ''} */}
        </Title>
      )}
      <div style={{ height: `${textareaHeight}px` }}>
        {type === 'textarea' ? (
          <>
            <textarea {...props} value={value} autocomplete="off" />
          </>
        ) : (
          <InputField {...props} value={value} className={error && 'error'} autocomplete="off" />
        )}
      </div>
      {error && <div>{error?.message}</div>}
    </div>
  )
}

export default Input

import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import { graphql, useStaticQuery } from 'gatsby'
import Gallery from '../gallery'

const FullGallery = ({ location = null }) => {
  const [gallery, setGallery] = useState(false)

  useEffect(() => {
    if (location.search.includes('?gallery')) setGallery(true)
  }, [location])

  const onClose = () => {
    navigate(location.pathname)
    setGallery(false)
  }

  const params = new URLSearchParams(location?.search)
  const imageId = params.get('gallery') || null

  const {
    homeContent,
    buildingContent,
    experienceContent,
  } = useStaticQuery(graphql`
    query GalleryQuery {
      homeContent {
        id
        content {
          content {
            image {
              caption
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              thumb: file {
                childImageSharp {
                  gatsbyImageData(
                    width: 126
                    aspectRatio: 1
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
      buildingContent {
        id
        content {
          content {
            images {
              caption
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              thumb: file {
                childImageSharp {
                  gatsbyImageData(
                    width: 126
                    aspectRatio: 1
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            image {
              caption
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              thumb: file {
                childImageSharp {
                  gatsbyImageData(
                    width: 126
                    aspectRatio: 1
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
      experienceContent {
        id
        content {
          content {
            images {
              caption
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              thumb: file {
                childImageSharp {
                  gatsbyImageData(
                    width: 126
                    aspectRatio: 1
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
            image {
              caption
              file {
                id
                extension
                childImageSharp {
                  gatsbyImageData
                }
              }
              thumb: file {
                childImageSharp {
                  gatsbyImageData(
                    width: 126
                    aspectRatio: 1
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
  const nodes = []

  const items = [
    ...homeContent.content,
    ...buildingContent.content,
    ...experienceContent.content,
  ]
  items.forEach((item) => {
    item.content.forEach((el) => {
      if (el?.image && el?.image.file.extension === 'jpg') nodes.push(el?.image)
      el?.images?.forEach((im) => {
        if (im.file.extension === 'jpg') nodes.push(im)
      })
    })
  })

  return (
    <Gallery
      nodes={nodes}
      imageId={imageId}
      onClose={onClose}
      open={gallery}
      fileField
    />
  )
}

export default FullGallery
/* allFile(
        filter: {
          relativeDirectory: { in: ["home", "building", "experience"] }
          extension: { eq: "jpg" }
        }
      ) {
        nodes {
          id
          name
          publicURL
          childImageSharp {
            gatsbyImageData
          }
          thumb: childImageSharp {
            gatsbyImageData(
              width: 126
              aspectRatio: 1
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    } */

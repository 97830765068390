import React, { useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '../ui/button'
import FooterModal from '../footer/footer-modal'
import ImagesSubMenu from './images-submenu'
import LinksSubMenu from './links-submenu'
import {
  HeaderWrapper,
  Navigation,
  NavigationItem,
  NavigationLi,
  NavigationLinksWrapper,
  Logo,
  Submenu,
  SubmenuContent,
  RightButtons,
  HoverHighlight,
  CloseArrow,
} from './index.styled'
import PdfButton from '../ui/pdf-button'
import PdfPopup from '../ui/pdf-popup'
import HeaderMobile from './header-mobile'
import useWindowSize from '../../helpers/use-window-size'

const Header = ({ className, location }) => {
  const { isMobile } = useWindowSize()
  const [isFooter, setIsFooter] = React.useState(false)
  const [openPopupPdf, setOpenPopupPdf] = React.useState(false)
  const HoverHighlightRef = useRef(null)
  const {
    menuContent: { content: links },
  } = useStaticQuery(graphql`
    query MenuQuery {
      menuContent {
        content {
          text
          url
          submenu {
            type
            list {
              url
              text
              image {
                file {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      height: 200
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
              mobileImage: image {
                file {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(transformOptions: { cropFocus: CENTER })
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const onMouseOverFn = (e) => {
    const x = e.currentTarget.offsetLeft
    const w = e.currentTarget.clientWidth

    HoverHighlightRef.current.style.transform = `translateX(${x}px)`
    HoverHighlightRef.current.style.width = `${w}px`
  }

  const onMouseOutFn = () => {
    HoverHighlightRef.current.style.transform = `translateX(-100%)`
    HoverHighlightRef.current.style.width = `100px`
  }

  return (
    <>
      <FooterModal open={isFooter} onClose={() => setIsFooter(false)} />
      <HeaderWrapper className={[className]} id={'header'}>
        <Navigation>
          <Logo to="/" />

          <NavigationLinksWrapper>
            {links.map((item, index) => (
              <React.Fragment key={`header-item-${index}`}>
                <NavigationLi
                  onMouseOver={(e) => {
                    if (!!item?.submenu) onMouseOverFn(e)
                  }}
                  onMouseOut={(e) => {
                    if (!!item?.submenu) onMouseOutFn()
                  }}
                  className={[
                    !!item?.submenu ? 'submenu' : '',
                    location.pathname.startsWith(item.url) ? 'active' : '',
                  ].join(' ')}
                >
                  <NavigationItem to={item.url}>{item.text}</NavigationItem>
                  {!!item?.submenu && (
                    <Submenu>
                      <SubmenuContent>
                        {item?.submenu?.type === 'images' ? (
                          <ImagesSubMenu items={item?.submenu.list} />
                        ) : (
                          <LinksSubMenu items={item?.submenu.list} />
                        )}
                        <CloseArrow
                          onClick={(e) => {
                            e.currentTarget.parentElement.parentElement.classList.add(
                              'closeHover',
                            )
                            const target = e.currentTarget.parentElement
                            setTimeout(() => {
                              target.parentElement.classList.remove(
                                'closeHover',
                              )
                            }, 1000)
                          }}
                        />
                      </SubmenuContent>
                    </Submenu>
                  )}
                </NavigationLi>
              </React.Fragment>
            ))}
          </NavigationLinksWrapper>
          <RightButtons>
            <PdfButton onClick={() => setOpenPopupPdf(true)} />
            <Button
              title={'Contact'}
              white
              to="/"
              as="button"
              onClick={() => setIsFooter(!isFooter)}
            />
          </RightButtons>
        </Navigation>
        {!isMobile && (
          <PdfPopup open={openPopupPdf} onClose={() => setOpenPopupPdf(false)} />
        )}
        <HoverHighlight ref={HoverHighlightRef} />
      </HeaderWrapper>
      {isMobile && (
        <HeaderMobile />
      )}
    </>
  )
}

export default Header

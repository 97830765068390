import React from 'react'
import { useLocation } from '@reach/router'
import useWindowSize from '../../helpers/use-window-size'

import { Container, Left, Right } from './index.styled'

const items = [
  //TODO: get from menu data
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Building',
    link: '/building/',
  },
  {
    title: 'Experience',
    link: '/experience/',
  },
  {
    title: 'Availability',
    link: '/availability/',
  },
]

const NavigationPrevNext = () => {
  const location = useLocation()
  const { isMobile } = useWindowSize()

  const links = isMobile
    ? [
        ...items,
        {
          title: 'SL Green',
          link: '/sl-green/',
        },
      ]
    : items

  const index = links.findIndex(({ link }) => location.pathname === link)

  if (index === -1) return null

  const prev = index === 0 ? null : links[index - 1]
  const next = index + 1 >= links.length ? links[0] : links[index + 1]

  return (
    <Container>
      {prev ? <Left to={prev.link}>{prev.title}</Left> : <div />}
      <Right to={next.link}>{next.title}</Right>
    </Container>
  )
}

export default NavigationPrevNext
